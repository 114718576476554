<template>
   <div id="container">
      <v-card :class="{card:true,mobile:isMobile}">
         <img
             class="clo"
             @click="closeDialog"
             src="@/assets/btn_close.png"
             alt=""
         />
         <div class="top">
            <span class="title"> {{$t('detailIndexPlaceBid')}}</span>
            <h3 class="content">
               {{$t('popupAuctionDefectPriceOnceTheBid')}}
            </h3>
            <div class="form">
               <div class="ti-in">
                  <span class="titles">{{$t('popupAuctionDefectPriceYouMust')}}</span>
                  <br/>
                  <span class="minprice"> {{ value3 }} IRIS</span>
                  <v-text-field
                      v-model="fixPrice"
                      outlined
                      suffix="IRIS"
                      type="input"
                      class="textinput"
                      style="margin-top:8px;"
                  ></v-text-field>
               </div>
               <div>

               </div>
            </div>
         </div>
         <div class="footer">
            <span class="price">{{$t('popupAuctionDefectPriceHandingFee')}}：{{value4}} IRIS</span>
<!--            <span class="price">Gas：{{ value1 }} IRIS</span>-->
            <button class="sub" @click="subCommit">{{$t('popupAuctionDefectPriceInsufficientFunds')}}</button>
            <span class="price">{{$t('popupNewTransferBalance')}}：{{ value2 }} IRIS</span>
         </div>
      </v-card>
   </div>
</template>
<script>
   export default {
      data: () => ({
         open: true,
         fixPrice: "",
         value1: "0.065",
         value2: "123",
         value3: "16",
         value4: "2.00"
      }),
      computed: {
         isMobile: function () {
            return this.$vuetify.breakpoint.mobile;
         }
      },
      methods: {
         //提交转送数据
         subCommit() {
         },
         //关闭弹框，发送open值
         closeDialog() {
            this.open = false;
            this.$emit("getOpen", this.open);
         },
      },
   };
</script>
<style lang="scss" scoped>
   .card {
      display: flex;
      flex-direction: column;
      min-height: 492px;
      width: 600px;

      .clo {
         position: absolute;
         right: 10px;
         top: 10px;
         width: 30px;
         height: 31px;
         background-color: #fff;
         border-radius: 50%;
         margin-right: 0;
         cursor: pointer;
      }

      .top {
         display: flex;
         flex-direction: column;
         margin-left: 52px;

         .title {
            margin: 42px 0 0 0;
            width: 111px;
            height: 21px;
           font-family:Helvetica;
            font-size: 25px;
            font-weight: normal;
            font-stretch: normal;
            letter-spacing: 0px;
            color: #270645;
         }

         .content {
            margin: 10px 0 20px 0;
            width: 485px;
            height: 62px;
            font-family:Helvetica;
            font-size: 15px;
            font-weight: normal;
            font-stretch: normal;
            letter-spacing: 0px;
            color: #766983;
         }

         .form {
            .ti-in {
               .titles {
                  margin-top: 24px;
                  width: 152px;
                  height: 13px;
                  font-family:Helvetica;
                  font-size: 15px;
                  font-weight: normal;
                  font-stretch: normal;
                  line-height: 48px;
                  letter-spacing: 0px;
                  color: #270645;
               }

               .minprice {
                  width: 51px;
                  height: 13px;
                 font-family:Helvetica;
                  font-size: 15px;
                  font-weight: normal;
                  font-stretch: normal;
                  letter-spacing: 0px;
                  color: #270645;
               }

               .textinput {
                  width: 500px;
                  height: 75px;
                  background-image: linear-gradient(#ffffff, #ffffff),
                  linear-gradient(#f8f6fd, #f8f6fd);
                  background-blend-mode: normal, normal;
                  border-radius: 5px;
               }
            }
         }
      }

      .footer {
         display: flex;
         flex-direction: column;
         text-align: center;
         margin-bottom: 50px;

         .sub {
            margin: 20px auto;
            width: 450px;
            height: 51px;
            background-image: linear-gradient(
                    #766983,
                    #766983),
            linear-gradient(
                    #270645,
                    #270645);
            background-blend-mode: normal,
            normal;
            border-radius: 25px;
            opacity: 0.9;
            font-size: 20px;
            color: #ffffff;
         }

         .price {
            display: block;
            font-family:Helvetica;
            font-size: 13px;
            font-weight: normal;
            font-stretch: normal;
            color: #766983;
         }
      }

      &.mobile {
         .top{
            width:100%;
            margin-left:10%;
            .content{
               width:80%;
               height:auto;
            }
            .form{
            .ti-in{
               width:100%;
               .textinput{
                  width:80%;
               }
            }
         }
         }

         .footer{
            .sub{
               width:80%;
            }
         }
      }
   }


   img {
      display: inline-block;
      width: 126px;
      height: 126px;
      background-color: #8a73ff;
      border-radius: 5px;
      margin: 1px 22px 10px 52px;
   }


</style>




