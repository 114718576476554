<template>
  <div>
    <div v-if="!isStart">
      <div class="font-weight-bold" >
        <div style="color: #766983;font-size: 14px;!important">{{ $t("homeProductAuctionStartingIn") }}</div>
        <div class=" hms">{{ hour }}h {{ minutes }}m {{ second }}s</div>
      </div>
      <div class="font-weight-bold  mt-2">
        <div style="color: #766983;font-size: 14px;!important">{{ $t("workCardStartBid") }}</div>
        <div class="price">{{ value.auctionVo.lastBidPrice }} {{getChainNameByDenom(value.auctionVo.coinDenom)}}</div>
        <div class="usd" style="color: #766983">
              USD {{ usd }}
            </div>
      </div>
    </div>
    <div v-else>
      <div class="font-weight-bold">
        <div style="color: #766983;font-size: 14px;!important">{{ $t("homeProductAuctionEndingIn") }}</div>
        <div class=" hms">{{ hour }}h {{ minutes }}m {{ second }}s</div>
      </div>
      <div class="font-weight-bold mt-2">
        <div style="color: #766983;font-size: 14px;!important">{{ $t("workCardCurrentBid") }}</div>
        <div class=" d-flex  flex-row ">
          <div class="price">{{ value.auctionVo.lastBidPrice }} {{getChainNameByDenom(value.auctionVo.coinDenom)}}</div>

        <img  :class="[rotate?'go':'']" class="refresh" src="@/assets/icons/icon_refresh.png"  @click="refresh" />
        </div>
        <div class="usd" style="color: #766983">
              USD {{ usd }}
            </div>
      </div>
    </div>

  </div>
</template>

<script>
import api from "@/api";
export default {
  props: {
    value: {
      type: Object,
    },
  },
  data: () => ({
    isStart: false,
    now: "",
    start: "",
    end: "",
    day: 0,
    hour: 0,
    minutes: 0,
    second: 0,
    seconds: "",
   rotate:false,
   	usd: 0,
  }),
  mounted() {

	  let isShowCreate = sessionStorage.getItem("showCreate");
	  let isDeposit = sessionStorage.getItem("isDeposit");
	  
		    this.Time();
	
  
    let usd_price = sessionStorage.getItem("KEY_USD_PRICE");

     this.usd = this.getPriceByUsd(this.value.auctionVo.coinDenom,this.value.auctionVo.lastBidPrice )
	  // let isShowCreate = sessionStorage.getItem("showCreate");
	  // let isDeposit = sessionStorage.getItem("isDeposit");
	  // if(!isShowCreate || isDeposit != 3){
	  // this.isClick = false
	  // }
  },
  methods: {

    async refresh(){
     this.rotate=true;
     setTimeout(() => { this.rotate=false }, 2000);
     
     let param = {
       auctionNo:this.value.auctionVo.auctionNo
     }
     let result = await api.home.currentBid(param);
     this.value.auctionVo.lastBidPrice = result.data.lastBidPrice
    //  window.location.reload();
     
    },
    getTime() {
      this.now = Date.parse(new Date());
        this.start = this.value.auctionVo.startTime;
      this.end = this.value.auctionVo.endTime;
      // this.start = Date.parse(new Date("2022-01-08 19:41:00"));
      // this.end = Date.parse(new Date("2022-01-08 19:42:00"));

		 if (this.value.auctionVo.auctionStatus == 0 || this.value.auctionVo.auctionStatus == 1) {
		   if (this.now < this.start) {
		     this.isStart = false;
		     this.seconds = (this.start - this.now) / 1000;
		     this.$emit('auctionChange',false)
		   } else if (this.now < this.end && this.value.buyoutPrice != null && this.value.lastBidPrice< this.value.buyoutPrice) {
		     this.isStart = true;
		     this.seconds = (this.end - this.now) / 1000;
		     this.$emit('auctionChange',true)
		   }else if(this.now < this.end && this.value.buyoutPrice == null){
		      this.isStart = true;
		     this.seconds = (this.end - this.now) / 1000;
		     this.$emit('auctionChange',true)
		   }
		   else{
		     this.$emit('auctionChange',false)
		   }
		 } 
	  

   
    },
    // 天 时 分 秒 格式化函数
    countDown() {
      let d = parseInt(this.seconds / (24 * 60 * 60));
      this.day = d < 10 ? "0" + d : d;
      let h = parseInt(((this.seconds / (60 * 60)) % 24) + d * 24);
      this.hour = h < 10 ? "0" + h : h;
      let m = parseInt((this.seconds / 60) % 60);
      this.minutes = m < 10 ? "0" + m : m;
      let s = parseInt(this.seconds % 60);
      this.second = s < 10 ? "0" + s : s;
    },
    //定时器没过1秒参数减1
    Time() {
      setInterval(() => {
        if (this.seconds > 0) {
          this.seconds -= 1;
          this.countDown();
        } else {
          this.getTime();
        }
      }, 1000);
    },
  },
};
</script>

<style lang="scss" scoped>
.usd {
        font-family: Helvetica;
        font-size: 13px;
        font-weight: normal;
        font-stretch: normal;
        letter-spacing: 0px;
      }
.font-weight-bold {
  font-size: 15px;
  .hms {
    font-size: 20px;
  }
  .price {
    font-size: 30px;
  }
  .usd{
    margin-top: 2px;
     font-size: 13px;
  }
}
.refresh{
  height: 18px;
  width: 18px;
  margin-top:18px;
  margin-left: 30px;
}
  // .aa{
  //       transition: all 2s;
  //   }
    
    .go{
        transform:rotate(360deg);
        transition: all 2s;
        pointer-events: none;
        user-select: none;
    }

</style>
